import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { hideTooltipsAction } from 'actions/tooltipAction'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { createModalRoutes } from 'routes/layoutModalRoutes'
import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { InternalContent } from 'components/layout/MambaLayout/InternalContent'
import { fetchBannersAvailableAction } from 'actions/banner/bannerAction'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { bannerReducer } from 'reducers/banner/bannerReducer'
import { openEmojiPickerAction } from 'actions/emoji/openEmojiPickerAction'
import { toggleOpenContactsAction } from 'actions/messenger/toggleOpenContactsAction'
import { androidAppPath } from 'components/paths'
import {
  LayoutContext,
  useLayoutContextValue,
} from './MambaLayoutIndex.context'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { LOCALES_REGEXP } from 'common-constants/locale'
import { fetchGdprStatusAction } from 'actions/user/fetchGdprStatusAction'
import { settingsReducer } from 'reducers/settings/settingsReducer'

const MambaLayoutIndex: FC<PropsWithChildren> = ({ children }) => {
  useReducersInsert({ bannerReducer, settingsReducer: settingsReducer })
  const dispatch = useDispatch()
  const {
    authorized,
    basePath,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { basePath },
    }) => ({ authorized, basePath })
  )

  const hasUserRestrictions = useHasUserRestrictions()

  useEffect(() => {
    if (!hasUserRestrictions) {
      dispatch(fetchBannersAvailableAction())
      dispatch(fetchGdprStatusAction())
    }
  }, [dispatch, hasUserRestrictions, authorized])

  const contextValue = useLayoutContextValue()

  if (basePath === androidAppPath) {
    /**
     * Иначе ошибка типов.
     */
    return <>{children}</>
  }

  const handleCloseClick = () => {
    dispatch(hideTooltipsAction())
    dispatch(openEmojiPickerAction(false))
    dispatch(toggleOpenContactsAction(false))
  }

  const modalRoutes = createModalRoutes(authorized)

  /**
   * https://redmine.mamba.ru/issues/119930
   * В момент авторизаций с url /ru/login/ и после удаление locale из url.
   * Путем RouteSwitch.tsx:105 в basePath остается /ru.
   * Из-за этого уже собранные модальные url не попадают под правила.
   * Поэтому нужно удалить locale из basePath
   */
  const _basePath = authorized
    ? basePath.replace(`:locale(${LOCALES_REGEXP})`, '')
    : basePath

  return (
    <LayoutContext.Provider value={contextValue}>
      <InternalContent
        modal={
          <LocaleSwitch
            id="mamba layout modal"
            matchPath={_basePath}
            routes={modalRoutes}
          />
        }
        onClick={handleCloseClick}
      >
        {children}
      </InternalContent>
    </LayoutContext.Provider>
  )
}

export default MambaLayoutIndex
